<template>
    <b-container class="p-0">

        <TopbarUserMenu />

    </b-container>
</template>

<script>
    import TopbarUserMenu from "@/components/app/frontpage/TopbarUserMenu";

    export default {
        name: "TopbarRightMenu",
        components: {
            TopbarUserMenu,
        }
    }
</script>