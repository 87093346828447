<template>
    <b-navbar-nav>
        <b-dropdown-item href="#">
            <b-icon icon="shop"></b-icon>
            <router-link to='/clientes'> Cadastro de Clientes</router-link>
        </b-dropdown-item>
    </b-navbar-nav>
</template>

<script>
    export default {
        name: "TopbarDataMenu"
    }
</script>