<template>

    <!-- ADRs Logo -->
    <b-navbar-brand href="#">
        <b-container class="p-0 m-0" width="100px">
            <b-img :src="require('@/assets/img/logo_white_mini.png')" width="100%"></b-img>
        </b-container>
    </b-navbar-brand>
    <!-- End of ADRs Logo -->

</template>

<script>
    export default {
        name: "ADRsLogo"
    }
</script>

<style scoped>

</style>