<template>
    <footer class="fixed-bottom" sticky-bottom>
        <b-row>
            <b-col md="12" class="text-center border-top border-success bg-light small shadow">
                Copyright© <strong>ADRs</strong> Systems
            </b-col>
        </b-row>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>