import Vue from 'vue';
import Router from 'vue-router';

// Components
import Login from '@/components/app/auth/Login';
import Logout from '@/components/app/auth/Logout';

import Clientes from '@/components/clientes/Index';

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      path: '/login',
      component: Login
    },
    {
      path: '/logout',
      component: Logout
    },
    {
      path: '/clientes',
      component: Clientes
    },
    {
      path: '/',
      component: Clientes
    },
  ]
});